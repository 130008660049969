<template>
  <v-dialog
    v-model="addMediaVisible"
    scrollable persistent
    width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="btnDisabledAddMedia"
        class="btn ml-2"
        v-bind="attrs"
        v-on="on"
        text
      >{{ $t('Add') }}</v-btn>
    </template>
    <v-card class="popup">
      <div class="popup-titbox _between">
        <v-card-title>
        <h3 v-if="mediaType === 'video'" class="page-title-bar"><i class="ico ico-media-list"></i>{{ $t('ADD Video') }}</h3>
        <h3 v-if="mediaType === 'image'" class="page-title-bar"><i class="ico ico-media-list"></i>{{ $t('ADD Image') }}</h3>
        </v-card-title>
      </div>
      <v-card-text>
      <!-- <div class="custom-scrollBox"> -->
        <div class="product_detail">
          <table>
            <colgroup>
              <col style="width:120px">
              <col>
            </colgroup>
            <!-- <tr>
              <td>Media Name</td>
              <td><v-text-field
                v-model="formData.media_name"></v-text-field></td>
            </tr> -->
            <tr>
              <td class="tdCategoryClass">{{$t('Category')}}</td>
              <td>
                <v-row>
                  <v-col md="6">
                    <v-text-field
                      :disabled="categoryDisabled"
                      outlined
                      dense
                      hide-details
                      v-model="formData.category"></v-text-field>
                  </v-col
                   >
                  <v-col md="6">
                    <v-select
                    outlined
                    dense
                      :items="category"
                      v-model="selectCategory"></v-select>
                  </v-col>
                </v-row>
              </td>
            </tr>
            <tr>
              <td>{{$t('Media Files')}} <br> ({{$t('Max')}}: 5)</td>
              <td class="mediafileClass">
                <!-- 아래의 두 줄 추가하여 아이콘 등록 및 아이콘 클릭 이벤트 작성 필요 -->
                <!-- :prepend-icon="'@/assets/img/ico-upload-file'"
                @click:prepend="" -->
                <v-btn
                  icon
                  @click="openFileDialog">
                  <i style="margin-left: 15px" class="ico ico-upload-file" :style="{ 'background-position': 'center' }"></i>
                </v-btn>
                <v-chip-group
                  column
                >
                  <v-chip
                    x-small
                    v-for="(item, index) in fileList"
                    :key="index"
                    close
                    close-icon="mdi-delete"
                    @click:close="deleteFileList(index)"
                  >
                  {{ item.name }}
                  </v-chip>
                </v-chip-group>
                <v-file-input
                  v-if="mediaType === 'video'"
                  v-model="formData.file"
                  hide-input
                  prepend-icon=""
                  small-chips
                  multiple
                  outlined
                  dense
                  ref="media_file"
                  @change="fileCheck($event, 'video')"
                  accept=".mp4">
                  <template v-slot:prepend-inner>
                    <!-- <div @click="uploadFile"> -->
                      <i class="ico ico-upload-file"></i>
                    <!-- </div> -->
                  </template>
                  </v-file-input>
                <v-file-input
                  v-if="mediaType === 'image'"
                  v-model="formData.file"
                  prepend-icon=""
                  hide-input
                  small-chips
                  outlined
                  dense
                  multiple
                  ref="media_file"
                  @change="fileCheck($event, 'image')"
                  accept=".jpg, .png">
                  <template v-slot:prepend-inner>
                    <!-- <div @click="uploadFile"> -->
                      <i class="ico ico-upload-file"></i>
                    <!-- </div> -->
                  </template></v-file-input>
              </td>
            </tr>
            <tr>
              <td>Explain</td>
              <td ><v-textarea
                  no-resize
                  outlined
                  class="tdExplainClass"
                  rows="3"
                  v-model="formData.explain"></v-textarea></td>
            </tr>
          </table>
        </div>
      <!-- </div> -->
    </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn @click="handleSaveClick" class="btn" text icon>{{ $t('Save') }}</v-btn>
        <v-btn @click="cancelAddMedia" class="btn" text icon>{{ $t('Cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="isLoading" persistent>
      <v-progress-circular
        style="width:100%;"
        indeterminate
        :size="50"
        :width="7"
        color="#ddd"
      ></v-progress-circular>
    </v-dialog>
  </v-dialog>
</template>

<script>
import EventBus from '@/plugins/eventBus.js'
import commons from '@/plugins/commons'
import dicamoApi from '@/lcd/plugins/dicamoApi'

export default {
  props: {
    btnDisabledAddMedia: Boolean,
    user: Object,
    mediaType: String
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      addMediaVisible: false,
      // btnDisabledAddMedia: false,
      formData: {
        media_name: '',
        category: '',
        file: [],
        explain: ''
      },
      errCount: 0,
      success: 0,
      selectCategory: '',
      category: [],
      fileList: [],
      categoryDisabled: false
    }
  },
  mounted () {
    this.getCategory()
  },
  methods: {
    openFileDialog () {
      this.formData.file = []
      this.$refs.media_file.$refs.input.click()
    },
    deleteFileList (index) {
      // this.formData.file.splice(index, 1)
      this.fileList.splice(index, 1)
    },
    fileCheck (e, type) {
      const list = this.fileList
      let maxSize = 0
      let duplication = 0
      if (!e) return
      e.forEach((item) => {
        const filename = String(item.name).split('.')
        if (item.size > 52428800) {
          // this.fileList.splice(index, 1)
          maxSize++
          return
        }
        this.fileList.forEach((file, index) => {
          if (item.name === file.name) {
            this.fileList.splice(index, 1)
            duplication++
          }
        })
        list.push(item)
        if (type === 'video') {
          if (String(filename[filename.length - 1]).toLowerCase() !== 'mp4') {
            this.formData.file = []
            EventBus.$emit('messageAlert', this.$t('Invalid file extension'))
          }
        } else if (type === 'image') {
          if (String(filename[filename.length - 1]).toLowerCase() !== 'jpg' && String(filename[filename.length - 1]).toLowerCase() !== 'png') {
            this.formData.file = []
            EventBus.$emit('messageAlert', this.$t('Invalid file extension'))
          }
        }
      })

      if (maxSize > 0) {
        EventBus.$emit('messageAlert', this.$t('Can\'t register over 50MB each file.'))
      }
      if (duplication > 0) {
        EventBus.$emit('messageAlert', this.$t('Can\'t register the same file.'))
      }
      if (list.length > 5) {
        // const overSize = list.length - 5
        EventBus.$emit('messageAlert', this.$t('Can\'t register 5 files at same time.'))
        this.fileList = list.splice(5)
      } else {
        this.fileList = list
      }
      this.formData.file = null
      this.fileList = list
      // const filename = String(e.name).split('.')
      // if (type === 'video') {
      //   if (String(filename[filename.length - 1]).toLowerCase() !== 'mp4') {
      //     this.formData.file = ''
      //     EventBus.$emit('messageAlert', this.$t('Invalid file extension'))
      //   }
      // } else if (type === 'image') {
      //   if (String(filename[filename.length - 1]).toLowerCase() !== 'jpg' && String(filename[filename.length - 1]).toLowerCase() !== 'png') {
      //     this.formData.file = ''
      //     EventBus.$emit('messageAlert', this.$t('Invalid file extension'))
      //   }
      // }
    },
    async handleSaveClick () {
      await this.addMedias()
    },
    convertCategory (items) {
      const list = []
      items.forEach(function (value) {
        list.push(value.category)
      })
      this.category = list
      this.formData.category = this.category[0]
      this.selectCategory = this.formData.category
    },
    getCategory () {
      const form = new FormData()
      form.append('company', this.$store.state.auth.user.company)
      form.append('store', this.$store.state.dataStore.selectedStore.code)
      form.append('timezone', this.$store.state.dataStore.timezone)
      form.append('type', 'category')

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getCategory.method,
          dicamoApi.requests.getCategory.url,
          form
        )
        .then(res => {
          this.convertCategory(res.data.data)
        })
        .catch(error => {
          EventBus.$emit('messageAlert', this.$t('something went wrong'))
          console.debug(`Failed to update a media error: ${error}`)
        })
        .finally(() => {
          this.addMediaVisible = false
        })
    },
    addMedias () {
      this.errCount = 0
      this.success = 0
      let index = 0
      if (this.fileList.length === 0) {
        EventBus.$emit('messageAlert', this.$t('Enter valid data'))
        return
      }
      if (!this.fileList) {
        return
      }
      this.fileList.forEach(item => {
        if (String(item.type).indexOf('image') > -1) {
          this.postMediaAPI(item, 0, index)
          index++
        }
        var video = document.createElement('video')
        const file = item
        const vue = this
        video.src = window.URL.createObjectURL(file)

        video.addEventListener('loadedmetadata', function () {
          const duration = video.duration
          vue.postMediaAPI(item, duration, index)
          index++
        })

        video.onload = () => {
          window.URL.revokeObjectURL(this.src)
        }
      })
    },
    postMediaAPI (item, duration, index) {
      this.errCount = 0
      this.success = 0
      const count = this.fileList.length
      const medianame = item.name.split('.')
      const medias = {
        media_name: medianame[0],
        category: this.formData.category,
        media_file: item,
        explain: this.formData.explain,
        store: this.$store.state.dataStore.selectedStore.code,
        company: this.user.company,
        duration: duration || 1
      }
      const isValidData = commons.isValidData(
        {
          'Media name': item.name,
          Category: medias.category,
          File: item
        }
      )
      if (!isValidData.result) {
        EventBus.$emit('messageAlert', this.$t(isValidData.msg))
        return
      }
      // const loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.formContainer,
      //   canCancel: false
      // })
      this.isLoading = true

      const form = new FormData()
      form.append('media_name', medias.media_name)
      form.append('index', index)
      form.append('category', medias.category)
      form.append('media_file', medias.media_file)
      form.append('explain', medias.explain)
      form.append('store', medias.store)
      form.append('company', medias.company)
      form.append('timezone', this.$store.state.dataStore.timezone)
      form.append('duration', medias.duration)
      // const medias = [productToUpdate]
      // const params = {
      //   store: this.$store.state.dataStore.selectedStore.code,
      //   company: this.user.company
      // }
      // const config = { params }
      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.addMedia.method,
          dicamoApi.requests.addMedia.url,
          form
        )
        .then(res => {
          if (res.data.result) {
            this.success++
          // this.$emit('fireResetMedia')
          // EventBus.$emit('messageAlert', this.$t('SUCCESS'))
          } else {
            this.errCount++
          // this.$emit('fireResetMedia')
          // EventBus.$emit('messageAlert', this.$t(res.data.message))
          }
          if (count === this.success + this.errCount) {
            if (this.errCount === 0) EventBus.$emit('messageAlert', this.$t('SUCCESS'))
            else EventBus.$emit('messageAlert', res.data.message)
            this.isLoading = false
            this.$emit('fireResetMedia')
            this.cancelAddMedia()
          }
        // loader.hide()
        })
        .catch(error => {
          this.errCount++
          if (count === this.success + this.errCount) {
            this.isLoading = false
          }
          EventBus.$emit('messageAlert', this.$t(`Upload failed ${this.errCount}Files`))
          // EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
          console.debug(`Failed to update a media error: ${error}`)
        })
    },
    cancelAddMedia () {
      this.addMediaVisible = false
      this.init()
    },
    init () {
      this.formData.media_name = ''
      this.formData.category = this.category[0]
      // this.formData.file = []
      this.formData.explain = ''
      this.selectCategory = this.formData.category
      this.fileList = []
    }
  },
  watch: {
    selectCategory: {
      handler () {
        this.formData.category = this.selectCategory
      }
    }
  }
}
</script>

<style scoped>
.mediafileClass{
  border-collapse: collapse;
    border-color: darkgray;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
}
.tdCategoryClass{
  display: flex;
  margin-top: 20px;
}
.tdExplainClass{
  display: flex !important;
  margin-top: 36px !important;
}
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }

</style>

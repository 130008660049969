var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", persistent: "", width: "600" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn ml-2",
                      attrs: { disabled: _vm.btnDisabledAddMedia, text: "" }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("Add")))]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.addMediaVisible,
        callback: function($$v) {
          _vm.addMediaVisible = $$v
        },
        expression: "addMediaVisible"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup" },
        [
          _c(
            "div",
            { staticClass: "popup-titbox _between" },
            [
              _c("v-card-title", [
                _vm.mediaType === "video"
                  ? _c("h3", { staticClass: "page-title-bar" }, [
                      _c("i", { staticClass: "ico ico-media-list" }),
                      _vm._v(_vm._s(_vm.$t("ADD Video")))
                    ])
                  : _vm._e(),
                _vm.mediaType === "image"
                  ? _c("h3", { staticClass: "page-title-bar" }, [
                      _c("i", { staticClass: "ico ico-media-list" }),
                      _vm._v(_vm._s(_vm.$t("ADD Image")))
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _c("v-card-text", [
            _c("div", { staticClass: "product_detail" }, [
              _c("table", [
                _c("colgroup", [
                  _c("col", { staticStyle: { width: "120px" } }),
                  _c("col")
                ]),
                _c("tr", [
                  _c("td", { staticClass: "tdCategoryClass" }, [
                    _vm._v(_vm._s(_vm.$t("Category")))
                  ]),
                  _c(
                    "td",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled: _vm.categoryDisabled,
                                  outlined: "",
                                  dense: "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.formData.category,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "category", $$v)
                                  },
                                  expression: "formData.category"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  items: _vm.category
                                },
                                model: {
                                  value: _vm.selectCategory,
                                  callback: function($$v) {
                                    _vm.selectCategory = $$v
                                  },
                                  expression: "selectCategory"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.$t("Media Files")) + " "),
                    _c("br"),
                    _vm._v(" (" + _vm._s(_vm.$t("Max")) + ": 5)")
                  ]),
                  _c(
                    "td",
                    { staticClass: "mediafileClass" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: { click: _vm.openFileDialog }
                        },
                        [
                          _c("i", {
                            staticClass: "ico ico-upload-file",
                            staticStyle: { "margin-left": "15px" },
                            style: { "background-position": "center" }
                          })
                        ]
                      ),
                      _c(
                        "v-chip-group",
                        { attrs: { column: "" } },
                        _vm._l(_vm.fileList, function(item, index) {
                          return _c(
                            "v-chip",
                            {
                              key: index,
                              attrs: {
                                "x-small": "",
                                close: "",
                                "close-icon": "mdi-delete"
                              },
                              on: {
                                "click:close": function($event) {
                                  return _vm.deleteFileList(index)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                      _vm.mediaType === "video"
                        ? _c("v-file-input", {
                            ref: "media_file",
                            attrs: {
                              "hide-input": "",
                              "prepend-icon": "",
                              "small-chips": "",
                              multiple: "",
                              outlined: "",
                              dense: "",
                              accept: ".mp4"
                            },
                            on: {
                              change: function($event) {
                                return _vm.fileCheck($event, "video")
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend-inner",
                                  fn: function() {
                                    return [
                                      _c("i", {
                                        staticClass: "ico ico-upload-file"
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              1732020553
                            ),
                            model: {
                              value: _vm.formData.file,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "file", $$v)
                              },
                              expression: "formData.file"
                            }
                          })
                        : _vm._e(),
                      _vm.mediaType === "image"
                        ? _c("v-file-input", {
                            ref: "media_file",
                            attrs: {
                              "prepend-icon": "",
                              "hide-input": "",
                              "small-chips": "",
                              outlined: "",
                              dense: "",
                              multiple: "",
                              accept: ".jpg, .png"
                            },
                            on: {
                              change: function($event) {
                                return _vm.fileCheck($event, "image")
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend-inner",
                                  fn: function() {
                                    return [
                                      _c("i", {
                                        staticClass: "ico ico-upload-file"
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              1732020553
                            ),
                            model: {
                              value: _vm.formData.file,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "file", $$v)
                              },
                              expression: "formData.file"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("td", [_vm._v("Explain")]),
                  _c(
                    "td",
                    [
                      _c("v-textarea", {
                        staticClass: "tdExplainClass",
                        attrs: { "no-resize": "", outlined: "", rows: "3" },
                        model: {
                          value: _vm.formData.explain,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "explain", $$v)
                          },
                          expression: "formData.explain"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.handleSaveClick }
                },
                [_vm._v(_vm._s(_vm.$t("Save")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.cancelAddMedia }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        },
        [
          _c("v-progress-circular", {
            staticStyle: { width: "100%" },
            attrs: { indeterminate: "", size: 50, width: 7, color: "#ddd" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-media-list" }),
        _vm._v(_vm._s(_vm.$t("Media List")) + " ")
      ]),
      _c(
        "v-tabs",
        {
          staticClass: "mt-6",
          attrs: {
            "background-color": "transparent",
            "hide-slider": "",
            "center-active": "",
            "active-class": "activeTab"
          },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-tab",
            {
              staticClass: "primary--text",
              staticStyle: { width: "30%" },
              attrs: { href: "#video" }
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("VIDEO")))])]
          ),
          _c(
            "v-tab",
            {
              staticClass: "primary--text tab-divider",
              staticStyle: { width: "30%" },
              attrs: { href: "#image" }
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("IMAGE")))])]
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "rounded-lg tab-body" },
        [
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _vm.tab === "video"
                ? _c(
                    "v-tab-item",
                    {
                      attrs: {
                        value: "video",
                        transition: false,
                        "reverse-transition": false
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pt-1 pa-8" },
                            [
                              _c(
                                "div",
                                { staticClass: "searchArea w50 mt-0" },
                                [
                                  _c("v-combobox", {
                                    staticClass: "form-combobox bg-gray",
                                    staticStyle: { "max-width": "150px" },
                                    attrs: {
                                      items: _vm.searchByOptions,
                                      "item-text": "text",
                                      "item-value": "value",
                                      outlined: "",
                                      dense: "",
                                      type: "button",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.search,
                                      callback: function($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    staticClass: "form-input in-btn ml-2",
                                    staticStyle: { "max-width": "296px" },
                                    attrs: {
                                      placeholder: _vm.getPlaceholder(
                                        _vm.search.text
                                      ),
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      clearable: ""
                                    },
                                    on: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.searchAnchors($event)
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: _vm.searchAnchors
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/img/btn-grey_search.jpg"),
                                                      alt: ""
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3728185629
                                    ),
                                    model: {
                                      value: _vm.search.value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.search, "value", $$v)
                                      },
                                      expression: "search.value"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mt-4",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c(
                                        "v-data-table",
                                        {
                                          staticClass: "gray-data-table",
                                          attrs: {
                                            options: _vm.options,
                                            headers: _vm.headers,
                                            items: _vm.videoData,
                                            page: _vm.page,
                                            "hide-default-footer": ""
                                          },
                                          on: {
                                            "update:sort-by": _vm.updateTable,
                                            "update:sort-desc": _vm.updateTable
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "body",
                                                fn: function(ref) {
                                                  var items = ref.items
                                                  return [
                                                    items.length > 0
                                                      ? _c(
                                                          "tbody",
                                                          _vm._l(
                                                            items,
                                                            function(
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "tr",
                                                                {
                                                                  key: index,
                                                                  class: {
                                                                    selectedRow:
                                                                      item ===
                                                                      _vm.selected
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.selectItem(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("td", [
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          index +
                                                                            1 +
                                                                            (_vm.page -
                                                                              1) *
                                                                              10
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]),
                                                                  _c("td", [
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.category
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]),
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      !_vm.isTooltip
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "slide-text"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.media_name
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm.isTooltip
                                                                        ? _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs: {
                                                                                top:
                                                                                  "",
                                                                                "max-width":
                                                                                  "200"
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "activator",
                                                                                    fn: function(
                                                                                      ref
                                                                                    ) {
                                                                                      var on =
                                                                                        ref.on
                                                                                      var attrs =
                                                                                        ref.attrs
                                                                                      return [
                                                                                        _c(
                                                                                          "div",
                                                                                          _vm._g(
                                                                                            _vm._b(
                                                                                              {
                                                                                                staticClass:
                                                                                                  "media-text"
                                                                                              },
                                                                                              "div",
                                                                                              attrs,
                                                                                              false
                                                                                            ),
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                item.media_name
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    }
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                true
                                                                              )
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "tooltip-text"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        item.media_name
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      !_vm.isTooltip
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "slide-text"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.file_name
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm.isTooltip
                                                                        ? _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs: {
                                                                                top:
                                                                                  "",
                                                                                "max-width":
                                                                                  "200"
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "activator",
                                                                                    fn: function(
                                                                                      ref
                                                                                    ) {
                                                                                      var on =
                                                                                        ref.on
                                                                                      var attrs =
                                                                                        ref.attrs
                                                                                      return [
                                                                                        _c(
                                                                                          "div",
                                                                                          _vm._g(
                                                                                            _vm._b(
                                                                                              {
                                                                                                staticClass:
                                                                                                  "media-text"
                                                                                              },
                                                                                              "div",
                                                                                              attrs,
                                                                                              false
                                                                                            ),
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                item.file_name
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    }
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                true
                                                                              )
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "tooltip-text"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        item.file_name
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _c("tbody", [
                                                          _c("tr", [
                                                            _c(
                                                              "td",
                                                              {
                                                                attrs: {
                                                                  colspan: "4"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-center"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "No data available"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ])
                                                        ])
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1035398610
                                          )
                                        },
                                        [
                                          _c("template", { slot: "no-data" }, [
                                            _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("No data available")
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ])
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticStyle: { height: "496px" },
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "ml-4 video-preview",
                                          attrs: { flat: "" }
                                        },
                                        [
                                          _c("v-card-title", [
                                            _vm._v(_vm._s(_vm.$t("PREVIEW")))
                                          ]),
                                          _c("v-card-text", [
                                            _vm.selected
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      position: "relative",
                                                      width: "473px",
                                                      height: "250px"
                                                    }
                                                  },
                                                  [
                                                    _c("v-hover", {
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var hover =
                                                                ref.hover
                                                              return [
                                                                _c(
                                                                  "v-card",
                                                                  {
                                                                    staticStyle: {
                                                                      width:
                                                                        "100%",
                                                                      height:
                                                                        "100%",
                                                                      outline:
                                                                        "2px #031E37 solid"
                                                                    }
                                                                  },
                                                                  [
                                                                    hover &&
                                                                    !_vm.isPlay
                                                                      ? _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "play-button",
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              color:
                                                                                "white"
                                                                            },
                                                                            on: {
                                                                              click:
                                                                                _vm.videoSwitch
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi mdi-pause"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.isPlay
                                                                      ? _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "play-button",
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              color:
                                                                                "white"
                                                                            },
                                                                            on: {
                                                                              click:
                                                                                _vm.videoSwitch
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi mdi-play"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _c(
                                                                      "video",
                                                                      {
                                                                        ref:
                                                                          "video",
                                                                        staticClass:
                                                                          "play-video",
                                                                        attrs: {
                                                                          muted:
                                                                            "",
                                                                          loop:
                                                                            "",
                                                                          src:
                                                                            _vm.videoURL +
                                                                            _vm
                                                                              .selected
                                                                              .file_url,
                                                                          width: 473,
                                                                          height: 250
                                                                        },
                                                                        domProps: {
                                                                          muted: true
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        2461534965
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tab === "image"
                ? _c(
                    "v-tab-item",
                    {
                      attrs: {
                        value: "image",
                        transition: false,
                        "reverse-transition": false
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pt-1 pa-8" },
                            [
                              _c(
                                "div",
                                { staticClass: "searchArea w50 mt-0" },
                                [
                                  _c("v-combobox", {
                                    staticClass: "form-combobox bg-gray",
                                    staticStyle: { "max-width": "150px" },
                                    attrs: {
                                      items: _vm.searchByOptions,
                                      "item-text": "text",
                                      "item-value": "value",
                                      type: "button",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.search,
                                      callback: function($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    staticClass: "form-input in-btn ml-2",
                                    staticStyle: { "max-width": "296px" },
                                    attrs: {
                                      placeholder: _vm.getPlaceholder(
                                        _vm.search.text
                                      ),
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      clearable: ""
                                    },
                                    on: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.searchAnchors($event)
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: _vm.searchAnchors
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/img/btn-grey_search.jpg"),
                                                      alt: ""
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3728185629
                                    ),
                                    model: {
                                      value: _vm.search.value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.search, "value", $$v)
                                      },
                                      expression: "search.value"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mt-4",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-data-table", {
                                        staticClass: "gray-data-table",
                                        attrs: {
                                          headers: _vm.headers,
                                          items: _vm.imageData,
                                          page: _vm.page,
                                          "hide-default-footer": ""
                                        },
                                        on: {
                                          "update:sort-by": _vm.updateTable,
                                          "update:sort-desc": _vm.updateTable
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "body",
                                              fn: function(ref) {
                                                var items = ref.items
                                                return [
                                                  items.length > 0
                                                    ? _c(
                                                        "tbody",
                                                        _vm._l(items, function(
                                                          item,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "tr",
                                                            {
                                                              key: index,
                                                              class: {
                                                                selectedRow:
                                                                  item ===
                                                                  _vm.selected
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.selectItem(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("td", [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      index +
                                                                        1 +
                                                                        (_vm.page -
                                                                          1) *
                                                                          10
                                                                    )
                                                                  )
                                                                ])
                                                              ]),
                                                              _c("td", [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.category
                                                                    )
                                                                  )
                                                                ])
                                                              ]),
                                                              _c(
                                                                "td",
                                                                [
                                                                  !_vm.isTooltip
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "slide-text"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.media_name
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.isTooltip
                                                                    ? _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              "",
                                                                            "max-width":
                                                                              "200"
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  var attrs =
                                                                                    ref.attrs
                                                                                  return [
                                                                                    _c(
                                                                                      "div",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            staticClass:
                                                                                              "media-text"
                                                                                          },
                                                                                          "div",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            item.media_name
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "tooltip-text"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.media_name
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "td",
                                                                [
                                                                  !_vm.isTooltip
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "slide-text"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.file_name
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.isTooltip
                                                                    ? _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              "",
                                                                            "max-width":
                                                                              "200"
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  var attrs =
                                                                                    ref.attrs
                                                                                  return [
                                                                                    _c(
                                                                                      "div",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            staticClass:
                                                                                              "media-text"
                                                                                          },
                                                                                          "div",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            item.file_name
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "tooltip-text"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.file_name
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        }),
                                                        0
                                                      )
                                                    : _c("tbody", [
                                                        _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                colspan: "4"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "text-center"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "No data available"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1035398610
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticStyle: { height: "496px" },
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "ml-4 video-preview",
                                          attrs: { flat: "" }
                                        },
                                        [
                                          _c("v-card-title", [
                                            _vm._v(_vm._s(_vm.$t("PREVIEW")))
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "video-preview" },
                                            [
                                              _c("v-card-text", [
                                                _vm.selected !== null
                                                  ? _c("div", {
                                                      staticClass:
                                                        "thumbnail_img",
                                                      style: {
                                                        "background-image":
                                                          "url(" +
                                                          _vm.srcURL +
                                                          JSON.parse(
                                                            _vm.selected
                                                              .still_shot
                                                          ) +
                                                          ")",
                                                        "margin-top": "100px",
                                                        "background-size":
                                                          "contain",
                                                        "background-position":
                                                          "center",
                                                        width: "100%",
                                                        height: "100%"
                                                      },
                                                      attrs: {
                                                        alt: "",
                                                        title:
                                                          _vm.selected
                                                            .file_name,
                                                        height: "250"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "table-options",
                  staticStyle: { margin: "30px" }
                },
                [
                  _c(
                    "div",
                    [
                      _c("media-add-dialog", {
                        attrs: {
                          user: _vm.user,
                          mediaType: _vm.tab,
                          btnDisabledAddMedia: _vm.addBtnDisabled
                        },
                        on: { fireResetMedia: _vm.resetMedia }
                      }),
                      _c("media-edit-dialog", {
                        attrs: {
                          user: _vm.user,
                          mediaType: _vm.tab,
                          btnDisabledEditMedia: _vm.editBtnDisabled,
                          idx_media: _vm.selected ? _vm.selected.idx_media : "0"
                        },
                        on: { fireResetMedia: _vm.resetMedia }
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn ml-2",
                          attrs: { disabled: _vm.deleteBtnDisabled, text: "" },
                          on: {
                            click: function($event) {
                              _vm.deleteDialog = true
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Delete")) + " ")]
                      )
                    ],
                    1
                  ),
                  _c("div", [
                    _c("div", { staticClass: "pageInfo" }, [
                      _vm._v(_vm._s(_vm.pageInfoText))
                    ]),
                    _c(
                      "div",
                      { staticClass: "pagination" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "pagination-nav pagination-nav-prev",
                            class:
                              _vm.page < 10 ? "pagination-nav-disabled" : null,
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.goTenPageBackwards(
                                  _vm.page,
                                  _vm.paging
                                )
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets//img/ico-paging-prev.png"),
                                alt: "이전 10페이지 이동"
                              }
                            })
                          ]
                        ),
                        _c("v-pagination", {
                          staticStyle: { display: "block" },
                          attrs: {
                            length: _vm.totalPages * 1 || 1,
                            "total-visible": 10,
                            color: "#2f3b4c"
                          },
                          on: { input: _vm.paging },
                          model: {
                            value: _vm.page,
                            callback: function($$v) {
                              _vm.page = $$v
                            },
                            expression: "page"
                          }
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "pagination-nav pagination-nav-next",
                            class:
                              _vm.page + 10 > _vm.totalPages
                                ? "pagination-nav-disabled"
                                : null,
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.goTenPageForward(
                                  _vm.page,
                                  _vm.paging,
                                  _vm.totalPages
                                )
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets//img/ico-paging-next.png"),
                                alt: "다음 10페이지 이동"
                              }
                            })
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "goto-Page d-flex align-center" },
                          [
                            _c("label", [_vm._v(_vm._s(_vm.$t("Go to")))]),
                            _c("v-text-field", {
                              staticClass: "form-input noneline",
                              attrs: {
                                placeholder: "Page",
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                solo: ""
                              },
                              on: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.goToPage(
                                    _vm.goToPageInput,
                                    _vm.paging,
                                    _vm.totalPages
                                  )
                                }
                              },
                              model: {
                                value: _vm.goToPageInput,
                                callback: function($$v) {
                                  _vm.goToPageInput = $$v
                                },
                                expression: "goToPageInput"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "370" },
          model: {
            value: _vm.deleteDialog,
            callback: function($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup-success" },
            [
              _c("i", { staticClass: "ico-success" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/ico-caution.png"),
                    alt: ""
                  }
                })
              ]),
              _c("div", { staticClass: "msg" }, [
                _c("h3", [_vm._v(_vm._s(_vm.$t("Delete")))]),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("Are you sure you want to delete?")))
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center btnArea" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.deleteMedia }
                    },
                    [_vm._v(_vm._s(_vm.$t("Yes")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("No")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
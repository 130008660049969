<template>
  <div>
    <h2 class="page-title-bar">
      <i class="ico ico-media-list"></i>{{ $t("Media List") }}
    </h2>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      hide-slider
      center-active
      active-class="activeTab"
      class="mt-6"
    >
      <v-tab href="#video" class="primary--text" style="width: 30%">
        <span>{{ $t("VIDEO") }}</span>
      </v-tab>
      <v-tab href="#image" class="primary--text tab-divider" style="width: 30%">
        <span>{{ $t("IMAGE") }}</span>
      </v-tab>
    </v-tabs>
    <v-card class="rounded-lg tab-body">
      <v-tabs-items v-model="tab">
        <v-tab-item
          :value="'video'"
          :transition="false"
          :reverse-transition="false"
          v-if="tab === 'video'"
        >
          <v-card flat>
            <v-card-text class="pt-1 pa-8">
              <div class="searchArea w50 mt-0">
                <v-combobox
                  v-model="search"
                  :items="searchByOptions"
                  item-text="text"
                  item-value="value"
                  class="form-combobox bg-gray"
                  outlined
                  dense
                  type="button"
                  hide-details
                  style="max-width:150px;="
                >
                </v-combobox>
                <v-text-field
                  v-model="search.value"
                  @keyup.enter="searchAnchors"
                  :placeholder="getPlaceholder(search.text)"
                  outlined
                  dense
                  hide-details
                  class="form-input in-btn ml-2"
                  clearable
                  style="max-width: 296px"
                >
                  <template v-slot:append>
                    <v-btn @click="searchAnchors" text>
                      <img src="@/assets/img/btn-grey_search.jpg" alt="" />
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
              <v-row no-gutters class="mt-4">
                <v-col cols="8">
                  <v-data-table
                    :options="options"
                    :headers="headers"
                    :items="videoData"
                    @update:sort-by="updateTable"
                    @update:sort-desc="updateTable"
                    :page="page"
                    hide-default-footer
                    class="gray-data-table"
                  >
                    <template v-slot:body="{ items }">
                      <tbody v-if="items.length > 0">
                        <tr
                          v-for="(item, index) in items"
                          :key="index"
                          @click="selectItem(item)"
                          :class="{ selectedRow: item === selected }"
                        >
                          <td><div>{{ index + 1 + (page - 1) * 10 }}</div></td>
                          <td><div>{{ item.category }}</div></td>
                          <td>
                            <div class="slide-text" v-if="!isTooltip">
                              {{ item.media_name }}
                            </div>
                            <v-tooltip v-if="isTooltip" top max-width="200">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-on="on" v-bind="attrs" class="media-text">{{ item.media_name }}</div>
                              </template>
                              <div class="tooltip-text"> {{ item.media_name }} </div>
                            </v-tooltip>
                          </td>
                          <td>
                            <div class="slide-text" v-if="!isTooltip">
                              {{ item.file_name }}
                            </div>
                            <v-tooltip v-if="isTooltip" top max-width="200">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-on="on" v-bind="attrs" class="media-text">{{ item.file_name }}</div>
                              </template>
                              <div class="tooltip-text"> {{ item.file_name }} </div>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="4">
                            <p class="text-center">
                              {{ $t("No data available") }}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                    <template slot="no-data">
                      <p>
                        {{ $t("No data available") }}
                      </p>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="4" style="height: 496px">
                  <v-card flat class="ml-4 video-preview">
                    <v-card-title>{{$t('PREVIEW')}}</v-card-title>
                    <v-card-text>
                      <!-- <v-carousel
                        v-if="selected !== null"
                        tag="div"
                        cycle
                        interval="2500"
                        hide-delimiter-background
                        hide-delimiters
                        height="250"
                        :show-arrows="false"
                      >
                        <v-carousel-item
                          v-for="(item, index) in JSON.parse(
                            selected.still_shot
                          )"
                          :key="index"
                          :src="srcURL + item"
                          reverse-transition="fade-transition"
                          transition="fade-transition"
                        >
                        </v-carousel-item>
                      </v-carousel> -->
                      <div style="position: relative; width: 473px; height: 250px" v-if="selected">
                        <v-hover v-slot="{ hover }">
                          <v-card style="width: 100%; height: 100%; outline: 2px #031E37 solid;">
                            <v-btn icon v-if="hover && !isPlay" color="white" class="play-button" @click="videoSwitch"> <v-icon>mdi mdi-pause</v-icon> </v-btn>
                            <v-btn icon v-if="isPlay" color="white" class="play-button" @click="videoSwitch"> <v-icon>mdi mdi-play</v-icon> </v-btn>
                            <video class="play-video" muted loop :src="videoURL + selected.file_url" :width="473" :height="250" ref="video"></video>
                          </v-card>
                        </v-hover>
                      </div>
                      <!-- <img src="@/assets/img/img-video.png" alt="" width="356" height="253" /> -->
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item
          :value="'image'"
          :transition="false"
          :reverse-transition="false"
          v-if="tab === 'image'"
        >
          <v-card flat>
            <v-card-text class="pt-1 pa-8">
              <div class="searchArea w50 mt-0">
                <v-combobox
                  v-model="search"
                  :items="searchByOptions"
                  item-text="text"
                  item-value="value"
                  type="button"
                  class="form-combobox bg-gray"
                  outlined
                  dense
                  hide-details
                  style="max-width: 150px"
                >
                </v-combobox>
                <v-text-field
                  v-model="search.value"
                  @keyup.enter="searchAnchors"
                  :placeholder="getPlaceholder(search.text)"
                  outlined
                  dense
                  hide-details
                  class="form-input in-btn ml-2"
                  clearable
                  style="max-width: 296px"
                >
                  <template v-slot:append>
                    <v-btn @click="searchAnchors" text>
                      <img src="@/assets/img/btn-grey_search.jpg" alt="" />
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
              <v-row no-gutters class="mt-4">
                <v-col cols="8">
                  <v-data-table
                    :headers="headers"
                    :items="imageData"
                    :page="page"
                    @update:sort-by="updateTable"
                    @update:sort-desc="updateTable"
                    hide-default-footer
                    class="gray-data-table"
                  >
                    <template v-slot:body="{ items }">
                      <tbody v-if="items.length > 0">
                        <tr
                          v-for="(item, index) in items"
                          :key="index"
                          @click="selectItem(item)"
                          :class="{ selectedRow: item === selected }"
                        >
                          <td><div>{{ index + 1 + (page - 1) * 10 }}</div></td>
                          <td><div>{{ item.category }}</div></td>
                          <td>
                            <div class="slide-text" v-if="!isTooltip">
                              {{ item.media_name }}
                            </div>
                            <v-tooltip v-if="isTooltip" top max-width="200">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-on="on" v-bind="attrs" class="media-text">{{ item.media_name }}</div>
                              </template>
                              <div class="tooltip-text"> {{ item.media_name }} </div>
                            </v-tooltip>
                          </td>
                          <td>
                            <div class="slide-text" v-if="!isTooltip">
                              {{ item.file_name }}
                            </div>
                            <v-tooltip v-if="isTooltip" top max-width="200">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-on="on" v-bind="attrs" class="media-text">{{ item.file_name }}</div>
                              </template>
                              <div class="tooltip-text"> {{ item.file_name }} </div>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="4">
                            <p class="text-center">
                              {{ $t("No data available") }}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="4" style="height: 496px">
                  <v-card flat class="ml-4 video-preview">
                    <v-card-title>{{$t('PREVIEW')}}</v-card-title>
                    <div class="video-preview">
                      <v-card-text>
                        <!-- <img v-if="selected !== null" :src="srcURL + JSON.parse(selected.still_shot)" alt="" title="툴팁" height="250" class="thumbnail_img"/> -->
                        <div
                          v-if="selected !== null"
                          :style="{
                            'background-image':
                              'url(' +
                              srcURL +
                              JSON.parse(selected.still_shot) +
                              ')',
                            'margin-top': '100px',
                            'background-size': 'contain',
                            'background-position': 'center',
                            width: '100%',
                            height: '100%',
                          }"
                          alt=""
                          :title="selected.file_name"
                          height="250"
                          class="thumbnail_img"
                        ></div>
                      </v-card-text>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- button group -->
        <div class="table-options" style="margin: 30px">
          <div>
            <media-add-dialog
              @fireResetMedia="resetMedia"
              :user="user"
              :mediaType="tab"
              :btnDisabledAddMedia="addBtnDisabled"
            />
            <media-edit-dialog
              @fireResetMedia="resetMedia"
              :user="user"
              :mediaType="tab"
              :btnDisabledEditMedia="editBtnDisabled"
              :idx_media="selected ? selected.idx_media : '0'"
            />
            <!-- <v-btn
                :disabled="editBtnDisabled"
                @click="editMediaDialog()"
                text
                class="btn ml-2"
                >{{ $t('Edit') }}</v-btn
              > -->
            <!-- <v-btn
                :disabled="deleteBtnDisabled"
                @click="deleteMedia()"
                text
                class="btn ml-2"
                >{{ $t('Delete') }}</v-btn
              > -->
            <v-btn
              @click="deleteDialog = true"
              :disabled="deleteBtnDisabled"
              text
              class="btn ml-2"
              >{{ $t("Delete") }}
            </v-btn>
          </div>
          <!-- paging -->
          <div>
            <div class="pageInfo">{{ pageInfoText }}</div>
            <div class="pagination">
              <!-- 페이지 앞 뒤 이동 -->
              <button
                @click="goTenPageBackwards(page, paging)"
                :class="page < 10 ? 'pagination-nav-disabled' : null"
                class="pagination-nav pagination-nav-prev"
                type="button"
              >
                <img
                  src="@/assets//img/ico-paging-prev.png"
                  alt="이전 10페이지 이동"
                />
              </button>
              <v-pagination
                v-model="page"
                @input="paging"
                :length="totalPages * 1 || 1"
                :total-visible="10"
                color="#2f3b4c"
                style="display: block"
              ></v-pagination>
              <button
                @click="goTenPageForward(page, paging, totalPages)"
                :class="
                  page + 10 > totalPages ? 'pagination-nav-disabled' : null
                "
                class="pagination-nav pagination-nav-next"
                type="button"
              >
                <img
                  src="@/assets//img/ico-paging-next.png"
                  alt="다음 10페이지 이동"
                />
              </button>

              <div class="goto-Page d-flex align-center">
                <label>{{$t('Go to')}}</label>
                <v-text-field
                  v-model="goToPageInput"
                  class="form-input noneline"
                  placeholder="Page"
                  outlined
                  dense
                  hide-details
                  solo
                  @keyup.enter="goToPage(goToPageInput, paging, totalPages)"
                ></v-text-field>

                <!-- <button
                    @click="goToPage(goToPageInput, paging, totalPages)"
                    type="button"
                    class="btnGoToPage"
                  >
                    <i
                      class="v-icon notranslate mdi mdi-chevron-right theme--light"
                      aria-hidden="true"
                    >
                    </i>
                  </button> -->
              </div>
            </div>
          </div>
        </div>
      </v-tabs-items>
    </v-card>
    <v-dialog v-model="deleteDialog" width="370">
      <v-card class="popup-success">
        <i class="ico-success"
          ><img src="@/assets/img/ico-caution.png" alt=""
        /></i>
        <div class="msg">
          <h3>{{ $t("Delete") }}</h3>
          <p>{{ $t("Are you sure you want to delete?") }}</p>
        </div>
        <v-card-actions class="d-flex justify-center btnArea">
          <v-btn text icon class="btn" @click="deleteMedia">{{
            $t("Yes")
          }}</v-btn>
          <v-btn text icon @click="deleteDialog = false" class="btn">{{
            $t("No")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
import dicamoApi from '@/lcd/plugins/dicamoApi'
import MediaAddDialog from './modal/MediaAddDialog.vue'
import MediaEditDialog from './modal/MediaEditDialog.vue'
import Pagenation from '@/mixins/Pagenation'

export default {
  name: 'media',
  components: {
    MediaAddDialog,
    MediaEditDialog
  },
  mixins: [Pagenation],
  created () {
    this.user = this.$store.state.auth.user
  },
  data () {
    return {
      isTooltip: true,
      isPlay: true,
      errorMessage: '',
      showMediaDetailPage: false,
      srcURL: dicamoApi.requests.thumbnail.url,
      videoURL: dicamoApi.media,
      videoData: [],
      imageData: [],
      btnDisabledVideoAddDeleteEdit: false,
      btnDisabledImageAddDeleteEdit: false,
      // deleteMedia: false,
      goToPageInput: null,
      deleteDialog: false,
      tab: null,
      user: null,
      search: {
        text: 'Category',
        value: null,
        options: 'category'
      },
      options: null,
      selected: null,
      page: 1,
      limit: 10,
      // totalPages: 0,
      // pageInfoText: '',
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'number',
          width: '8%'
        },
        {
          text: this.$t('CATEGORY'),
          align: 'start',
          sortable: true,
          value: 'category',
          width: '22%'
        },
        {
          text: this.$t('TITLE'),
          align: 'start',
          sortable: true,
          value: 'media_name',
          width: '25%'
        },
        {
          text: this.$t('FILE NAME'),
          align: 'start',
          sortable: true,
          value: 'file_name',
          width: '40%'
        }
      ],
      mediaDataList: [],
      searchByOptions: [
        { text: this.$t('Category'), options: 'category' },
        { text: this.$t('Title'), options: 'title' },
        { text: this.$t('File Name'), options: 'filename' }
      ]
    }
  },
  mounted () {
    // Media List : Detail Page
    this.$store.dispatch('auth/getDisabledBtn', '23100').then((flag) => {
      this.showMediaDetailPage = flag
    })
    // Media List : Add / Update / Delete
    this.$store.dispatch('auth/getDisabledBtn', '23101').then((flag) => {
      this.btnDisabledVideoAddDeleteEdit = flag
    })
    // Media List : Add / Update / Delete
    this.$store.dispatch('auth/getDisabledBtn', '23102').then((flag) => {
      this.btnDisabledImageAddDeleteEdit = flag
    })
    EventBus.$emit('enableSelectedStores', true)
    if (!this.store.code) {
      EventBus.$emit('openSelectedStores')
    } else {
      this.getMediaData()
    }
    // this.getMediaData()
  },
  // create() {
  //   EventBus.$on('FileUploadSuccess', () => {
  //     EventBus.$emit('loadingOn')
  //   })
  // },
  methods: {
    updateTable () {
      this.page = 1
    },
    videoSwitch () {
      const video = this.$refs.video
      this.isPlay = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2
      if (this.isPlay) {
        video.pause()
      } else {
        video.play()
      }
    },
    // buildParmas () {
    //   const { sortBy, sortDesc, itemsPerPage } = this.options
    //   const params = { store: this.store.code }
    //   if (commons.isValidStr(this.searchBy.articleName)) {
    //     params.name = this.searchBy.articleName
    //   }
    //   if (commons.isValidStr(this.searchBy.articleId)) {
    //     params.id = this.searchBy.articleId
    //   }
    //   let sort = null
    //   if (commons.isSorting(sortBy, sortDesc)) {
    //     const sortKey = this.getArticleSortKey(sortBy[0])
    //     const sortOrder = sortDesc[0]
    //     sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
    //   }
    //   if (sort !== null) params.sort = sort
    //   if (itemsPerPage > 0) params.size = itemsPerPage
    //   return params
    // },
    goToPage (goToPageInput, paging, totalPages) {
      if (totalPages < goToPageInput) {
      } else {
        paging(Number(goToPageInput))
      }
    },
    resetMedia () {
      this.page = 1
      this.imageData = []
      this.videoData = []
      this.getMediaData()
      this.selected = null
    },
    getMediaData () {
      // if (commons.isNull(params.store)) return
      // if (this.productionType === dicamoApi.productionTypes.CLOUD) {
      //   params.company = this.$store.state.auth.user.company
      // }
      const form = new FormData()

      form.append('company', this.$store.state.auth.user.company)
      form.append('store', this.$store.state.dataStore.selectedStore.code)
      form.append('type', 'list')
      form.append('searchType', this.search.value ? this.search.options : '')
      form.append('searchWord', this.search.value)
      form.append('page', this.page)
      form.append('limit', dicamoApi.EXPORT_SIZE)
      form.append('timezone', this.$store.state.dataStore.timezone)
      form.append('mediaType', this.tab)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getMedia.method,
          dicamoApi.requests.getMedia.url,
          form
        )
        .then((res) => {
          this.videoData = []
          this.imageData = []
          const video = []
          const image = []
          this.mediaDataList = res.data.data
          res.data.data.forEach((item) => {
            if (item.media_type === 'video') video.push(item)
            else image.push(item)
            this.videoData = video
            this.imageData = image
          })
        })
        .catch((error) => {
          this.mediaDataList = []
          // this.totalMedia = this.mediaDataList[0].length
          EventBus.$emit('messageAlert', this.$t('something went wrong'))
          console.debug(`Could not find any medias. error: ${error}`)
        })
    },
    paging (page) {
      this.page = page
      // this.getMediaData()
    },
    selectItem (item) {
      this.selected = item
    },
    getPlaceholder (key) {
      const str = String(key).toLowerCase()
      return this.$t(`Input ${str}`)
    },
    searchAnchors () {
      this.page = 1
      this.getMediaData()
    },
    editMediaDialog () {
      EventBus.$emit('showEditMedia')
    },
    deleteMedia () {
      // if (confirm('Are you sure you want to delete it?')) {
      const form = new FormData()
      this.deleteDialog = false
      form.append('company', this.$store.state.auth.user.company)
      form.append('store', this.$store.state.dataStore.selectedStore.code)
      form.append('idx_media', this.selected.idx_media)
      form.append('timezone', this.$store.state.dataStore.timezone)
      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.deleteMediaItem.method,
          dicamoApi.requests.deleteMediaItem.url,
          form
        )
        .then((res) => {
          // 6월 배포에 공유되지 않았음. 7월 배포 시 공유해야함.
          if (res.data.result) {
            // console.log('성공')
            EventBus.$emit('messageAlert', this.$t('SUCCESS'))
            this.resetMedia()
          } else {
            // console.log('실패')
            EventBus.$emit('messageAlert', this.$t(res.data.message))
            this.resetMedia()
          }
        })
        .catch((error) => {
          this.mediaDataList = []
          console.debug(`Could not find any medias. error: ${error}`)
        })
      //   console.log('삭제' + this.selected.idx_media)
      // } else {
      //   console.log('취소')
      // }
    }
  },
  computed: {
    ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' }),
    addBtnDisabled () {
      return (
        (this.tab === 'video'
          ? this.btnDisabledVideoAddDeleteEdit
          : this.btnDisabledImageAddDeleteEdit) || !this.store.code
      )
    },
    editBtnDisabled () {
      return (
        (this.tab === 'video'
          ? this.btnDisabledVideoAddDeleteEdit
          : this.btnDisabledImageAddDeleteEdit) || commons.isNull(this.selected)
      )
    },
    deleteBtnDisabled () {
      return (
        (this.tab === 'video'
          ? this.btnDisabledVideoAddDeleteEdit
          : this.btnDisabledImageAddDeleteEdit) || commons.isNull(this.selected)
      )
    },
    totalRecords () {
      if (this.tab === 'video') { return this.videoData.length > 0 ? this.videoData.length * 1 : 0 } else return this.imageData.length > 0 ? this.imageData.length * 1 : 0
    },

    // totalRecords () {
    //   let count = 0
    //   if (!commons.isNull(this.lcdlist) && this.lcdlist.length > 0) count = this.lcdlist[0].total
    //   return count
    // },
    totalPages () {
      return Math.ceil(this.totalRecords / 10)
    },
    // pageInfoText () {
    //   // return ''
    //   const curPage = (this.page - 1) * 1 * 10 + 1
    //   const curPageTo = (this.page - 1) * 1 * 10 + this.totalRecords
    //   return curPage + ' to ' + curPageTo + ', ' + this.totalRecords + ' in total'
    // }
    pageInfoText () {
      // return ''
      const curPage = (this.page - 1) * 1 * 10 + 1
      // const curPageTo = (this.page - 1) * 10 + this.totalRecords
      let curPageTo = 0
      if (this.totalPages === this.page) curPageTo = this.totalRecords
      else curPageTo = curPage + 9
      return (
        curPage + this.$t(' to ') + curPageTo + ', ' + this.totalRecords + this.$t('in total')
      )
      // return '0 to 0, 0'
    }
  },
  watch: {
    tab: {
      handler () {
        this.page = 1
        this.selected = null
        this.search.value = ''
        this.getMediaData()
      }
    },
    store: {
      handler () {
        this.page = 1
        this.selected = null
        this.search.value = ''
        this.getMediaData()
      }
    },
    search: {
      handler () {
        this.search.value = ''
      }
    },
    selected: {
      handler () {
        this.isPlay = true
      }
    }
    // searchBy: {
    //   handler (newSearchBy) {
    //     const productList = {
    //       searchBy: newSearchBy
    //     }
    //     setTempObj('productList', productList)
    //   },
    //   deep: true
    // }
    // mediaDataList: {
    //   handler () {
    //     this.getMediaData()
    //   }
    // }
  }
}
</script>

<style scoped>
::v-deep .v-autocomplete.v-input > .v-input__control > .v-input__slot {
  cursor: pointer !important;
}
::v-deep .v-select__slot > input {
  text-align: left;
}
::v-deep .v-input input:focus::placeholder {
  color: #fff;
}
::v-deep .v-carousel .v-image__image {
  /* object-fit: fill; */
  background-size: contain !important;
}
::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #031e37 !important;
}
.selectedRow {
  color: white !important;
  background-color: #031e37 !important;
  font-weight: bold;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab.rounded-t-xl {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}
.v-application .primary--text {
  color: var(--v-primary-base) !important;
  /* caret-color: #ffffff !important; */
}
.v-tab:before {
  opacity: 0.12;
  border-radius: 8px 8px 0 0;
}
.v-tab--active.v-tab:not(:focus)::before {
  opacity: 0;
  border-radius: 8px 8px 0 0;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab--active {
  border-right: 3px solid #ccd2d7;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  border: none !important;
  z-index: 1;
}
::v-deep .theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #ECECEC !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab {
  min-height: 0px !important;
  left: 0px !important;
  min-width: 160px !important;
  font-size: 15px;
  border: none;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
  border-top: 1px solid #f9f9f9;
  border-left: 1px solid #ededed;
  background: #fff;
  position: relative;
  z-index: 9;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab + .v-tab {
  margin-left: -4px;
}
.theme--light.v-tabs-items {
  background-color: white !important;
}
.tab-body {
  border-radius: 0 0 8px 8px !important;
  box-shadow: 3px 1px 6px -4px rgb(0 0 0 / 20%),
    0px 2px 2px -1px rgb(0 0 0 / 14%) !important;
  border-left: 1px solid #ededed;
  margin-top: -2px;
  border-top: 1px solid #fbfbfb;
  margin-left: 0;
}
.v-text-field fieldset,
.v-text-field .v-input__control {
  border: 1px solid #36383f !important;
  background: #8f9197;
}
.pageInfo {
  font-size: var(--font18);
  color: #797979;
}
.thumbnail_img {
  width: 100%;
}
::v-deep .v-autocomplete.v-input > .v-input__control > .v-input__slot {
    cursor: pointer !important;
    position: absolute !important;
    top: -24px !important;

}
::v-deep .popup-success .ico-success{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 4px !important;
    left: 50%;
    width: 58px;
    height: 58px;
    background: #001E38;
    border: 2px solid #fff;
    border-radius: 50% !important;
    transform: translate(-50%, -50%);
}
.image-preview{
  display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    background: #F5F5F5;
    height: 277px;
}
.image-preview img {
    max-width: 100%;
    max-height: 100%;
}

.play-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.play-button {
  border: solid;
  background: black;
  display: block;
  font-size: 18px;
  left: 0;
  margin: 0 auto;
  padding: 5px 10px;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 43%;
}

.pageInfo {
  position: absolute;
  top: 0;
  right: 0;
  font-size: var(--font16);
  color: #808495;
  line-height: 1;
}

.media-text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 189px;
  white-space: nowrap;
}

.tooltip-text {
  white-space: normal !important;
  word-wrap: break-word !important;
}

::v-deep .video-preview .v-card__text {
    padding: 80px 0 97px;
    height: 278px;
}
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
